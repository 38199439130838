import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"


const StyledContainer = styled(Container)`
  max-width: 1100px;
  width: 100%;
  margin: 60px auto 0;
  padding: 0px;
`

const StyledSeriousTargets = styled.section`
  background-color: ${colors.grayLight};
  position: relative;
  border-bottom-width: 0;
  text-align: center;
  padding: 40px 20px 50px;
  
  ${breakpoint.small`
	text-align: left;
  `}

	${breakpoint.medium`
		padding: 60px;
	`}

  .heading {
	margin: 0px 20px;
	text-align: center;
	padding-bottom: 20px;
	
    ${breakpoint.medium`
      margin: 0px;
      padding-bottom: 60px;
    `}
    
    h2 { 
    	padding-bottom: 40px;
    }

    h3 { 
    	padding: 25px 0 10px;
		font-size: 22px;
		margin-bottom: 3px;
		
		${breakpoint.medium`
			font-size: 24px;
		`}
    }

	.content {
		  display: grid;
		  grid-auto-flow: row;
		  margin: 40px auto 0;
		  grid-gap: 0;
		  row-gap: 2em;

		  ${breakpoint.small`
		  grid-auto-flow: column;
		  grid-auto-columns: 1fr;
		  grid-column-gap: 3em;
		  `}

		a {
			color: ${colors.orange};
			font-weight: normal;
			font-size: 15px;
		}
		
		a:hover {
			color: ${colors.purpleDark};
			font-weight: normal;
		}
		
		 }
		  
	.targetImg {
		width: 200px;	
		margin: 0 auto;
	}
	
}

	.sub {
		margin-top: 1.2em;
		line-height: 1.4em;
		font-size: 16px;
		color: ${colors.grayDark};
		padding-inline: 30px;
		font-weight: bold!important;

		${breakpoint.medium`
		  margin-top: 2em;
		`}
	}
   
`

const SeriousTargets = () => {

	return (
    <StyledSeriousTargets>
      <StyledContainer>
        <div className="heading">
			<h2 data-aos="fade-up" data-aos-once="false">Three critical targets</h2>
			<p data-aos="fade-up" data-aos-once="false">COVID-19 and flu are highly contagious respiratory illnesses. The Visby Medical 
			Respiratory Health Test can differentiate between the causative viruses and give answers to help guide providers toward the most effective treatments.</p>
				<div className="content">
					<div className="target" data-aos-delay="100" data-aos="fade-up" data-aos-once="false">
						<StaticImage
							src="../../../assets/images/respiratory-health-test/targets/target1.png"
							width={200}
							alt="Flu A"
							className="targetImg"
						/>
						<h3>Flu A</h3>
						<p>According to the CDC, molecular assays, including RT-PCR, are recommended for testing respiratory tract specimens 
							from hospitalized patients because of their high sensitivity and high specificity.</p>
						<p>
						<a
                			href="https://www.cdc.gov/flu/professionals/diagnosis/rapidlab.htm"
               				target="_blank"
               				rel="noreferrer noopener"
							style={{display: "inline-block"}}
						 >Source</a></p>
					</div>
					<div className="target" data-aos-delay="200" data-aos="fade-up" data-aos-once="false">
						<StaticImage
							src="../../../assets/images/respiratory-health-test/targets/target3.png"
							width={200}
							alt="Flu B"
							className="targetImg"
						/>
						<h3>Flu B</h3>
						<p>The Infectious Diseases Society of America (IDSA) recommends use of rapid influenza molecular assays over rapid influenza 
							diagnostic tests (RIDTs) for detection of influenza viruses in respiratory specimens of outpatients.</p>
						<p>
							<a
                			href="https://www.cdc.gov/flu/professionals/diagnosis/consider-influenza-testing.htm"
               				target="_blank"
               				rel="noreferrer noopener"
							style={{display: "inline-block"}}
						 >Source</a></p>
					</div>
					<div className="target" data-aos-delay="300" data-aos="fade-up" data-aos-once="false">
						<StaticImage
							src="../../../assets/images/respiratory-health-test/targets/target2.png"
							width={200}
							alt="COVID-19"
							className="targetImg"
						/>
						<h3>COVID-19</h3>
						<p>The current gold standard is to perform reverse-transcription polymerase chain reaction (PCR) on nasopharyngeal samples. 
							Best-in-class assays demonstrate a limit of detection (LoD) of approximately 100 copies of viral RNA per milliliter of transport media.</p>
						<p>
						<a
                			href="https://pubmed.ncbi.nlm.nih.gov/33532847/"
               				target="_blank"
               				rel="noreferrer noopener"
							style={{display: "inline-block"}}
						 >Source</a></p>
					</div>
				</div>
			</div>
      </StyledContainer>
    </StyledSeriousTargets>
  )
}

export default SeriousTargets